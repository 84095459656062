<template>
  <PageCard
    pageName="FI Vertical Strategy"
    :iconClass="['cuis-file-code', 'card-icon-font']"
  >
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FinTech Market Reports</div>
            <b-card-body class="p-0">
              <div>
                Quickly assess market share rank by vendor or vendor-product
                combos. Change the basis of share from FI count to deposit
                accounts. Then filter the rankings by FI type, size, or other
                variables to produce strategic insight or marketing ammo. You
                can click any vendor’s client count to see a list of their
                current clients.
              </div>
              <b-card-img
                src="../../../../static/img/market_reports.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                The FinTech Market Reports cover many retail, business, and
                operating solutions ranging from online account opening to core
                processing.
              </div>
              <div class="mb-2">
                Covering over 100 U.S. banking vertical solutions and over
                11,000 financial institutions, FinTech Market reports provide
                decisioning context where it was previously non-existent.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/reports/market_reports' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <!-- <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FinTech Firmographics</div>
            <b-card-body class="p-0">
              <div>
                Who is your real competition? Other vendors offering your
                financial technology may not actually be your true competition –
                if they tend to attract a different FI segment. Our FinTech
                Firmographics reports allows you to quickly identify each
                vendor’s client composition.
              </div>
              <b-card-img
                src="../../../../static/img/firmographics.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                Sort by financial institution type size, geography, strategic
                focus, or your preferred segmentation variables. You can click
                any vendor’s client count to see a list of their current
                clients.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/reports/firmographics' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col> -->
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN Industry Reporter</div>
            <b-card-body class="p-0">
              <div>
                FIN Industry Reporter allows users to analyze the banking
                industry in an unprecedented manner. Create financial
                institution peer groups, segmentation schemes and custom reports
                towards identifying the critical factors driving industry
                performance. Construct institution groups consisting of banks,
                credit unions or all institutions that can be compared to other
                peer groups or stratified by your segmentation methodology.
                Users can deploy segmentation ranging from market size to core
                technology deployed to stratify performance.
              </div>
              <b-card-img
                src="../../../../static/img/industry_reporter.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                Uniquely harness industry data to inform your strategy or
                highlight your client base’s higher performance.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{
                      path: '/reports/industry_reporter/select_fi_group'
                    }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN Digital Channels</div>
            <b-card-body class="p-0">
              <div>
                The FIN Digital Channels module delivers comprehensive data and
                innovative metrics providing insight into these critical
                performance areas:
              </div>

              <div class="my-2">
                <strong>Vendor Analytics.</strong> Covering over 50 vendors
                providing mobile/online banking applications, the Mobile Banking
                module produces comparative vendor performance dashboards
                including market share, share accretion, win-loss counts, churn
                rates (shown below), customer utilization, customer
                satisfaction, client demographics, and individual vendor
                profile.
              </div>

              <div class="my-2">
                <strong>Institution Analytics.</strong> Covering over 7,000
                banks and credit unions offering mobile/online banking
                applications, the institution analytics generate comparative
                segment performance dashboards including mobile adoption, vendor
                churn rates, customer utilization (shown below), mobile feature
                offering, customer satisfaction and individual institution
                profiles with peer comparisons.
              </div>
              <b-card-img
                src="../../../../static/img/digital_channels_mobile_banking.png"
                class="rounded-0 my-2"
              />
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{
                      path: '/digital_channels/about'
                    }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutMarketReports',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Vertical Strategy'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped></style>
